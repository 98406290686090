@import "color.scss";



.main {
  position: relative;
  padding-bottom: 70px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 45px;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 25px;
  }

  .decor {
    position: absolute;
    top: -300px;
    right: -715px;
    width: 1378px;
    height: 1378px;
    z-index: -20;
    @media screen and (max-width: 1199px) {
      width: 949px;
      height: 949px;
      right: -480px;
      top: -255px;
    }
    @media screen and (max-width: 767px) {
      right: auto;
      left: 50%;
      top: -100%;
      transform: translate(-50%, -50%);
    }

    .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.02);
      background-color: rgba(251, 251, 251, .019);
    }

    .circle1 {
      width: 100%;
      height: 100%;
    }
    .circle2 {
      width: 87.5%;
      height: 87.5%;
    }
    .circle3 {
      width: 75%;
      height: 75%;
    }
    .circle4 {
      width: 62.5%;
      height: 62.5%;
    }
    .circle5 {
      width: 50%;
      height: 50%;
    }
    .circle6 {
      width: 37.5%;
      height: 37.5%;
    }
    .circle7 {
      width: 25%;
      height: 25%;
    }
    .circle8 {
      width: 12.5%;
      height: 12.5%;
    }
  }

  .bg-img {
    position: absolute;
    top: 70px;
    right: -205px;
    width: 870px;
    height: 611px;
    object-fit: contain;
    object-position: left;
    @media screen and (max-width: 1400px) {
      width: 650px;
      height: 470px;
    }
    @media screen and (max-width: 1199px) {
      top: 115px;
      right: -120px;
      width: 590px;
      height: 403px;
    }
    @media screen and (max-width: 959px) {
      right: -270px;
    }

    @media screen and (max-width: 767px) {
      top: -270px;
      right: -60px;
      width: 380px;
      height: 250px;
    }
  }

  &__content {
    @media screen and (max-width: 767px) {
      margin-top: 280px;
    }
  }

  &__title {
    max-width: 1020px;
    margin-top: 75px;
    @media screen and (max-width: 1199px) {
      max-width: 710px;
      margin-top: 45px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__subtitle {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 4.7rem;
    font-weight: 800;
    line-height: 107%;
    @media screen and (max-width: 1199px) {
      margin-top: 15px;
      margin-bottom: 40px;
      font-size: 3.2rem;
      letter-spacing: -0.64px;
    }
    @media screen and (max-width: 767px) {
      font-size: 2.4rem;
    }
  }

  .text-wrapper {

  }

  &__text {
    position: relative;
    display: block;
    max-width: 590px;
    padding-left: 40px;
    font-size: 2.4rem;
    line-height: 150%;
    &:first-child  {
      margin-bottom: 25px;
      @media screen and (max-width: 1199px) {
        margin-bottom: 30px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      background-image: url("../img/checkmark.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      @media screen and (max-width: 1199px) {
        width: 20px;
        height: 20px;
      }
      @media screen and (max-width: 767px) {
        width: 15px;
        height: 15px;
      }
    }
    @media screen and (max-width: 1199px) {
      max-width: 440px;
      padding-left: 30px;
      font-size: 2rem;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      font-size: 1.5rem;
      padding-left: 25px;
    }
  }


  &__btn {
    margin-top: 50px;
    font-size: 2.2rem;
    @media screen and (max-width: 1199px) {
      margin-top: 35px;
    }
  }
}


.advantage {
  padding: 70px 0 35px;
  @media screen and (max-width: 1199px) {
    padding: 40px 0 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 0;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      align-items: start;
    }
  }

  .list-item {
    @media screen and (max-width: 767px) {
      width: 40%;
      margin-bottom: 25px;
    }
  }

  &__img {
    width: 90px;
    height: 90px;
    margin-bottom: 14px;
    object-fit: contain;
    @media screen and (max-width: 1199px) {
      width: 70px;
      height: 70px;
    }
    @media screen and (max-width: 959px) {
      width: 50px;
      height: 50px;
    }
  }

  &__text {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 155%;
    @media screen and (max-width: 1199px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 959px) {
      font-size: 1.5rem;
    }
  }
}

.calculation {
  padding: 35px 0;
  @media screen and (max-width: 1199px) {
    padding: 25px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      max-width: 860px;
      margin: 0 auto;
    }
  }

  &__form {
    position: relative;
    width: 100%;
    max-width: 800px;
    padding: 30px 30px 50px;
    border-radius: 10px;
    background: #FBFBFB;
    @media screen and (max-width: 1199px) {
      max-width: none;
      order: 1;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      padding: 25px 15px 35px;
    }
  }

  .form-title {
    max-width: 690px;
    margin-bottom: 25px;
    color: $main-body;
  }

  &__form-body {

    .primary {
      margin-left: 160px;
      font-size: 1.8rem;
      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 425px) {
        width: 100%;

      }
    }
  }

  .input-wrap {
    position: relative;
    display: flex;
    padding-left: 160px;
    @media screen and (max-width: 767px) {
      padding-left: 120px;
    }
    @media screen and (max-width: 359px) {
      padding-left: 95px;
    }
  }

  .input-label {
    position: absolute;
    left: 0;
    max-width: 135px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      max-width: 100px;
      font-size: 1.5rem;
    }
    @media screen and (max-width: 359px) {
      max-width: 80px;
      font-size: 1.2rem;
    }
  }

  .form-comment {
    max-width: 526px;
    width: 100%;
    height: 130px;
    padding: 14px 13px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    resize: none;
    border-radius: 3px;
    border: 1px solid rgba(15, 44, 115, 0.20);
    background: #EAECF1;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  .form-upload {
    display: flex;
    align-items: center;
    margin: 20px 0 35px 160px;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      margin: 15px 0 25px 120px;
    }
    @media screen and (max-width: 359px) {
      margin: 15px 0 25px 95px;
    }

    .upload-icon {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url("../img/upload-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      @media screen and (max-width: 767px) {
        width: 13px;
        height: 13px;
      }
    }

    .upload-name {
      position: relative;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 1.2rem;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px dashed rgba(14, 25, 50, .4);
      }
    }
  }

  .form-input {
    max-width: 287px;
    width: 100%;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__download {
    position: relative;
    display: block;
    padding-left: 35px;
    margin-top: 25px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 140%;
    color: $white-text;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: #A3A3A766;
    @media screen and (max-width: 1199px) {
      margin-bottom: 50px;
      order: 2;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-image: url("../img/pdf.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
    }
  }

  &__btn-block {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 340px;
    height: fit-content;
    padding: 30px 25px;
    border-radius: 10px;
    background: #1C3050;
    @media screen and (max-width: 1199px) {
      max-width: none;
      width: 100%;
      order: 3;
    }
    @media screen and (max-width: 767px) {
      align-items: center;
      text-align: center;
    }
    .title {
      width: 200px;
      @media screen and (max-width: 1199px) {
        width: auto;
      }
    }

    .text {
      margin: 20px 0 30px;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      @media screen and (max-width: 1199px) {
        max-width: 495px;
        width: 100%;
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
        margin: 10px 0 20px;
      }
    }

    .primary {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 140%;
      @media screen and (max-width: 1199px) {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate(0, -50%);
        max-width: 175px;
        font-size: 1.6rem;
        padding: 17px 18px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        transform: translate(0, 0);
      }
    }
  }
}

.catalog {
  padding: 100px 0 70px;
  @media screen and (max-width: 767px) {
    padding: 50px 0 40px;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .headline-text {
    max-width: 750px;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }

    .title {
      margin-bottom: 25px;
    }

    .text {
      max-width: 565px;
      color: #FBFBFB;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 155%;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
    }
  }

  .headline-slider {
    position: relative;
    width: 240px;
    @media screen and (max-width: 767px) {
      margin: 25px auto 0;
    }
  }

  .certificate-slider {
    width: 100%;
    overflow: hidden;

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .certificate-img {
    width: 177px;
    height: 246px;
    border-radius: 3px;
  }
  .certificate-pagination {
    width: fit-content;
    margin: 15px auto 0;
  }
  .certificate-button-prev,
  .certificate-button-next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
  }

  .certificate-button-prev {
    left: -20px;
  }

  .certificate-button-next {
    right: -20px;
  }

  &__body {
    width: 100%;
    padding-top: 80px;
    @media screen and (max-width: 767px) {
      padding-top: 30px;
    }
  }

  &__button {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 30px;
    color: $white-text;
    opacity: 0.3;
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 107%;
    letter-spacing: -0.44px;
    text-decoration: underline;
    cursor: pointer;
    transition: .3s;
    &.active {
      opacity: 1;
    }
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 425px) {
      font-size: 1.1rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    @media screen and (max-width: 767px) {
      max-width: 440px;
      margin: 0 auto;
    }
    @media screen and (max-width: 425px) {
      padding-bottom: 0;
    }
  }

  .catalog-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 260px;
    max-height: 280px;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #F5F5F5;
    overflow: hidden;
    transition: .3s;
    &.active {
      display: none;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: start;
      min-height: auto;
      padding: 20px 15px 25px;
    }
    @media screen and (max-width: 425px) {
      align-items: start;
      margin-bottom: 5px;
    }
    @media screen and (max-width: 359px) {
      padding: 20px 10px 25px;
    }
  }

  .item-img {
    width: 280px;
    height: 260px;
    object-fit: cover;
    object-position: right;
    @media screen and (max-width: 1199px) {
      width: 195px;
      height: 220px;
    }
    @media screen and (max-width: 959px) {
      width: 150px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .item-desc {
    max-width: 535px;
    margin-left: 48px;
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .item-title {
    margin-bottom: 30px;
    color: #0E1932;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 116%;
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  .item-feature {
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 1199px) {
      margin-bottom: 14px;
      font-size: 1.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .feature-title {
    opacity: .4;
  }

  .feature-desc {

  }

  .button-block {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    align-items: end;
    @media screen and (max-width: 1199px) {
      right: 20px;
    }
    @media screen and (max-width: 767px) {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
      flex-direction: row;
      width: 100%;
      margin-top: 17px;
      margin-right: 0;
    }
  }

  .button-decor {
    position: relative;
    padding: 10px 11px 10px 23px;
    margin-bottom: 70px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 135%;
    border-radius: 3px;
    background: #DFE2EA;
    @media screen and (max-width: 1199px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translate(0, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $marker-color;
    }
  }

  .item-btn {
    display: flex;
    justify-content: center;
    padding: 22px 25px;
    font-weight: 600;
    line-height: 120%;
    color: $dark-text;
    border-radius: 3px;
    background-color: $btn-primary;
    font-size: 2rem;
    cursor: pointer;
    transition: .3s;
    &:hover {
      background: transparent;
      box-shadow: 0 0 0 2px rgba(255, 182, 0, 1) inset;
    }
    @media screen and (max-width: 1199px) {
      max-width: 120px;
      padding: 0;
      font-size: 1.8rem;
      background-color: transparent;
      text-decoration: underline;
      text-decoration-color: transparent;
      &:hover {
        background: transparent;
        color: $dark-text;
        box-shadow: none;
        text-decoration-color: #b0b0b0;
      }
    }
    @media screen and (max-width: 767px) {
      max-width: fit-content;
      background: $btn-primary;
      padding: 10px;
      margin-right: 5px;
      font-size: 1.2rem;
      text-decoration: none;
      order: -1;
      &:hover {
        box-shadow: 0 0 0 2px rgba(255, 182, 0, 1) inset;
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 45px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #F6F6F7;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      padding: 35px 30px;
    }
    @media screen and (max-width: 767px) {
      max-width: 440px;
      padding: 12px 17px 25px;
      margin: 0 auto;
    }

    .card-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 726px;
      height: 382px;
      object-fit: contain;
      @media screen and (max-width: 1199px) {
        width: 546px;
        height: 298px;
      }
      @media screen and (max-width: 959px) {
        width: 380px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
      }
    }

    .title {
      max-width: 760px;
      color: $main-body;
      z-index: 5;
      @media screen and (max-width: 1199px) {
        max-width: 435px;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.8rem;
      }
    }

    .card-list {
      padding-left: 28px;
      margin-top: 30px;
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        margin-top: 15px;
        margin-bottom: 17px;
        padding-left: 15px;
      }
    }

    .list-item {
      position: relative;
      color: $main-body;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 140%;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -24px;
        transform: translate(0, -50%);
        background-image: url("../img/check.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 19px;
        height: 13px;
        @media screen and (max-width: 767px) {
          left: -12px;
          width: 9px;
          height: 6px;
        }
      }
    }
    .primary {
      max-width: 270px;
      padding: 22px 0;
      font-size: 2rem;
      font-weight: 600;
      line-height: 120%;
      z-index: 5;
      @media screen and (max-width: 767px) {
        max-width: 160px;
        padding: 10px 0;
        font-size: 1.2rem;
      }
    }

  }
}

.provider {
  padding: 70px 0 45px;
  @media screen and (max-width: 767px) {
    padding: 40px 0 25px;
  }

  .title {
    max-width: 1000px;
    margin-bottom: 70px;
    @media screen and (max-width: 1199px) {
      max-width: 100%;
      margin-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .subtitle {
    max-width: 395px;
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.7px;
    @media screen and (max-width: 1199px) {
      max-width: 620px;
      margin-bottom: 35px;
      font-size: 2.8rem;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
      margin-bottom: 15px;
      font-size: 2rem;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  &__body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    @media screen and (max-width: 767px) {
      align-items: center;
    }
  }

  &__body {
    position: relative;
    max-width: 730px;
    width: 100%;
    height: 430px;
    padding: 20px 20px 0;
    border-radius: 5px;
    background: $white-text;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 140%;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 70px;
      border-radius: 5px;
      background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      @media screen and (max-width: 767px) {
        height: 30px;
      }
    }
    @media screen and (max-width: 767px) {
      max-width: 380px;
      height: 210px;
      padding: 5px 10px 0;
      margin: 0 auto;
    }
  }

  .headline {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #0E1932;
      opacity: .1;
      @media screen and (max-width: 767px) {
        bottom: 5px;
      }
    }
  }

  .headline-title {
    color: #0E1932;
    font-weight: 700;
    font-size: 1.3rem;
    @media screen and (max-width: 767px) {
      font-size: 0.6rem;
    }
    &:first-child {
      flex-basis: 15%;
    }

    &:nth-child(2) {
      flex-basis: 30%;
    }

    &:nth-child(3) {
      flex-basis: 30%;
    }

    &:last-child {
      flex-basis: 17%;
    }
  }

  &__scroll {
    height: 380px;
    padding-bottom: 20px;
    overflow-x:auto;
    @media screen and (max-width: 767px) {
      height: 190px;
      padding-bottom: 10px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      font-size: 0.6rem;
    }
  }

  .city {
    flex-basis: 15%;
  }

  .company {
    flex-basis: 30%;
  }

  .object {
    flex-basis: 30%;
  }

  .sum {
    flex-basis: 17%;
  }

  &__text {
    margin-top: 20px;
    color: #F5F5F5;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 155%;
    }
  }
}

.about {
  padding: 45px 0 80px;
  @media screen and (max-width: 1199px) {
    padding: 25px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
  }

  &__wrapper-left {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    @media screen and (max-width: 1199px) {
      flex-direction: row;
      justify-content: space-between;
      max-width: 100%;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      max-width: 460px;
    }
    @media screen and (max-width: 820px) {
      max-width: 420px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  &__subtitle {
    max-width: 390px;
    margin: 20px 0 50px;
    color: #FBFBFB;
    font-size: 2rem;
    font-weight: 400;
    line-height: 155%;
    opacity: .8;
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin: 10px 0 25px;
      font-size: 1.3rem;
    }
  }

  .list-icon {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 300px;
    height: fit-content;
    &:after {
      content: '';
      display: block;
      width: 140px;
      @media screen and (max-width: 767px) {
        width: 100px;
      }
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .icon-item {
    width: 140px;
    height: 42px;
    margin-bottom: 25px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 100px;
      height: 27px;
      margin-bottom: 20px;
    }
  }

  &__wrapper-right {
    position: relative;
    width: 660px;
    height: 440px;
    border-radius: 5px;
    @media screen and (max-width: 1199px) {
      max-width: 860px;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-decor {
    position: absolute;
    left: -70px;
    bottom: -170px;
    @media screen and (max-width: 1199px) {
      left: 35px;
      bottom: -150px;
    }
    @media screen and (max-width: 767px) {
      left: 15px;
    }
    .img {
      width: 196px;
      height: 272px;
      object-fit: contain;
      @media screen and (max-width: 767px) {
        width: 129px;
        height: 179px;
      }
    }

    .text {
      max-width: 450px;
      margin-top: 15px;
      color: #F5F5F5;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
    }
  }

  .img-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 73px;
    height: 73px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 44px;
      height: 44px;
    }
  }

  &__advantage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 320px;
    margin-top: 160px;
    @media screen and (max-width: 1199px) {
      margin-top: 220px;
      height: 295px;
    }
    @media screen and (max-width: 767px) {
      align-items: baseline;
      height: fit-content;
      margin-top: 185px;
    }
  }

  .advantage-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 35px 85px 35px 30px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    &:first-child {
      flex-basis: 63%;
      @media screen and (max-width: 767px) {
        flex-basis: 48%;
      }
    }
    &:last-child {
      flex-basis: 35.5%;
      @media screen and (max-width: 767px) {
        flex-basis: 48%;
      }
    }
    @media screen and (max-width: 1199px) {
      padding: 35px 55px 35px 30px;
    }
    @media screen and (max-width: 767px) {
      justify-content: start;
      background-color: transparent;
      padding: 0;
    }
  }

  .item-icon {
    width: 105px;
    height: 95px;
    margin-bottom: 30px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 65px;
      height: 59px;
      margin-bottom: 15px;
    }
  }

  .item-text {
    color: #F5F5F5;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.7px;
    @media screen and (max-width: 1199px) {
      font-size: 2.8rem;
    }

    @media screen and (max-width: 959px) {
      font-size: 2.4rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__gallery {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 50px;
    }
  }

  .gallery-text {
    display: flex;
    flex-direction: column;
    max-width: 390px;
    @media screen and (max-width: 1199px) {
      max-width: 300px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      order: 2;
    }
  }

  .title {
    @media screen and (max-width: 1199px) {
      font-size: 2.8rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 25px;
      font-size: 2rem;
    }
  }

  .subtitle {
    margin: 20px 0 30px;
    color: #FBFBFB;
    font-size: 2rem;
    font-weight: 400;
    line-height: 155%;
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 1.3rem;
    }
  }

  .primary {
    max-width: 210px;
    padding: 17px 0;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 120%;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 375px) {
      max-width: 100%;
    }
  }

  .slider-wrapper {
    position: relative;
    max-width: 730px;
    @media screen and (max-width: 1199px) {
      max-width: 530px;
    }
    @media screen and (max-width: 959px) {
      max-width: 350px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      order: 1;
    }
    @media screen and (max-width: 425px) {

    }
  }

  .gallery-slider {
    position: relative;
    width: 100%;
    height: 440px;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      height: 353px;
    }
    @media screen and (max-width: 959px) {
      height: 230px;
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
    }
  }

  .about-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slide-title-mobile,
  .slide-subtitle-mobile {
    display: none;
    margin-top: 20px;
    max-width: 495px;
    color: #F5F5F5;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 959px) {
      display: block;
      max-width: 100%;
      margin-top: 10px;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
  }

  .slide-subtitle-mobile {
    margin: 20px 0;
    color: rgba(245, 245, 245, 0.40);
  }

  .slide-title,
  .slide-subtitle {
    margin-top: 20px;
    max-width: 495px;
    color: #F5F5F5;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 959px) {
      display: none;
    }
  }

  .slide-subtitle {
    color: rgba(245, 245, 245, 0.40);
  }

  .gallery-pagination {
    display: none;
    justify-content: center;
    margin: 10px auto;
    @media screen and (max-width: 959px) {
      display: flex;
      justify-content: start;
    }

    .swiper-pagination-bullet {
      width: 10%;
      height: 5px;
      border-radius: 16px;
      opacity: 0.2;
      background: #37B2EF;
      cursor: pointer;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }

  .gallery-button-prev,
  .gallery-button-next {
    position: absolute;
    top: 200px;
    background-color: $white-text;
    z-index: 10;
    @media screen and (max-width: 1199px) {
      top: 160px;
    }
    @media screen and (max-width: 959px) {
      display: none;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .gallery-button-prev {
    left: -20px;
    &:before {
      border-left: 1px solid #0F2C73;
      border-bottom: 1px solid #0F2C73;
    }
  }

  .gallery-button-next {
    right: -20px;
    &:before {
      border-top: 1px solid #0F2C73;
      border-right: 1px solid #0F2C73;
    }
  }

  &__advantage-two {
    margin-top: 90px;
    @media screen and (max-width: 1199px) {
      margin-top: 80px;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }

    .advantage-item {
      height: max-content;
      padding: 35px 30px;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }

  &__form {
    position: relative;
    width: 100%;
    padding: 45px 40px 55px;
    margin-top: 90px;
    border-radius: 5px;
    background-color: #FBFBFB;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 50px;
      background-image: url("../img/form-foto.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 530px;
      height: 404px;
      z-index: 1;
      @media screen and (max-width: 1199px) {
        background-image: url("../img/form-foto-2.png");
        width: 342px;
        height: 360px;
      }
      @media screen and (max-width: 959px) {
        width: 265px;
        height: 275px;
        right: 20px;
      }
      @media screen and (max-width: 767px) {
        background-image: url("../img/form-foto.png");
        width: 370px;
        left: 0;
        bottom: 0;
      }
      @media screen and (max-width: 425px) {
        width: 320px;
        height: 240px;
      }
      @media screen and (max-width: 375px) {
        width: 280px;
        height: 210px;
      }

    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("../img/decor-form.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 555px;
      height: 360px;
      @media screen and (max-width: 959px) {
        width: 495px;
        height: 320px;
        right: -140px;
      }

      @media screen and (max-width: 375px) {
        background-image: url("../img/decor-form-2.png");
        width: 100%;
        height: 195px;
        right: 0;
      }
    }

    @media screen and (max-width: 1199px) {
      padding: 35px 30px 45px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 15px 305px;
    }
    .title {
      max-width: 580px;
      color: #0E1932;
    }
  }

  &__form-subtitle {
    margin-top: 20px;
    margin-bottom: 35px;
    color: #0E1932;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  &__form-body {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 5;
    @media screen and (max-width: 1199px) {

    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .form-input {
    max-width: 210px;
    width: 100%;
    margin-right: 10px;
    @media screen and (max-width: 1199px) {
      max-width: 200px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .primary {
    max-width: 210px;
    width: 100%;
    @media screen and (max-width: 1199px) {

    }
    @media screen and (max-width: 767px) {
      max-width: 320px;
      margin: 0 auto;
    }
  }
}

.delivery {
  position: relative;
  padding-top: 60px;
  padding-bottom: 70px;
  @media screen and (max-width: 1199px) {
    padding-top: 95px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .title {

  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      flex-direction: row;
      justify-content: space-between;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .item-1 {
    width: 62%;
    @media screen and (max-width: 1199px) {
      width: 100%;
      margin-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .item-2 {
    width: 37%;
    @media screen and (max-width: 1199px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {

    }
  }

  .item-img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    @media screen and (max-width: 1199px) {
      order: 2;
      max-width: 460px;
      height: 320px;
    }
    @media screen and (max-width: 767px) {
      order: 1;
      max-width: 100%;
      height: 245px;
    }
  }

  .item-desc {
    margin-top: 20px;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.7px;
    @media screen and (max-width: 1199px) {
      max-width: 345px;
      width: 100%;
      margin-top: 0;
      order: 1;
      font-size: 2.8rem;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin-top: 15px;
      font-size: 2rem;
      order: 2;
      br {
        display: none;
      }
    }
  }

  &__map {
    position: relative;
  }

  .map-img-mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  &__map-text {
    max-width: 420px;
    margin-top: 130px;
    margin-bottom: 300px;
    @media screen and (max-width: 1199px) {
      margin-top: 70px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin-top: 0;
    }

    .title {
      @media screen and (max-width: 1199px) {
        font-size: 2.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
    }

    .subtitle {
      margin-top: 20px;
      font-size: 2rem;
      font-weight: 400;
      line-height: 155%;
      @media screen and (max-width: 1199px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
    }
  }

  &__map-block {
    position: absolute;
    bottom: -175px;
    right: -195px;
    width: 980px;
    height: 539px;
    z-index: -5;
    @media screen and (max-width: 1199px) {
      position: relative;
      bottom: 0;
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1059px;
      height: 583px;
      margin: -175px auto 0;
    }
    @media screen and (max-width: 959px) {
      left: 57%;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .map-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .map-city {
    position: absolute;
    color: #37B2EF;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 155%;

    .city-decor {
      position: absolute;
      left: 50%;
      bottom: 25px;
      transform: translate(-50%, 0);
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(55, 178, 239, .3);


      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #37B2EF;
      }
    }
    @keyframes bounce {
      0% {
        transform:translate(-50%, 0) scale(1);
      }

      50% {
        transform:translate(-50%, 0) scale(1.2);
      }

      100% {
        transform:translate(-50%, 0) scale(1);
      }
    }
  }

  .map-city-1 {
    top: 31%;
    right: 39%;
  }

  .map-city-2 {
    top: 43%;
    right: 52%;
  }

  .map-city-3 {
    top: 47%;
    right: 30%;
  }

  .map-city-4 {
    top: 60%;
    left: 11%;
  }

  .map-city-5 {
    bottom: 22%;
    left: 38%;
  }

  .map-city-6 {
    bottom: 20%;
    right: 21%;
  }

  .city-decor-1 {
    animation: 2s linear infinite bounce;

  }
  .city-decor-2 {
    animation: 3s linear infinite bounce;

  }
  .city-decor-3 {
    animation: 1.5s linear infinite bounce;

  }
  .city-decor-4 {
    animation: 2.5s linear infinite bounce;

  }
  .city-decor-5 {
    animation: 2s linear infinite bounce;

  }
  .city-decor-6 {
    animation: 3s linear infinite bounce;

  }

  &__form {
    position: relative;
    width: 100%;
    padding: 45px 40px 55px;
    margin-top: 90px;
    border-radius: 5px;
    background-color: #FBFBFB;
    @media screen and (max-width: 1199px) {
      padding: 35px 30px 45px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 15px 325px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("../img/hend.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 434px;
      height: 455px;
      z-index: 1;
      @media screen and (max-width: 1199px) {
        width: 427px;
        height: 448px;
      }
      @media screen and (max-width: 959px) {
        width: 278px;
        height: 292px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("../img/decor-form.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 555px;
      height: 360px;
      z-index: 0;

      @media screen and (max-width: 959px) {
        width: 495px;
        height: 320px;
        right: -140px;
      }
      @media screen and (max-width: 375px) {
        background-image: url("../img/decor-form-2.png");
        width: 100%;
        height: 195px;
        right: 0;
      }
    }

    .title {
      max-width: 580px;
      color: #0E1932;
      @media screen and (max-width: 1199px) {
        max-width: 410px;
        font-size: 2.8rem;
      }
      @media screen and (max-width: 767px) {

      }
    }

    .subtitle {
      max-width: 590px;
      margin-top: 20px;
      margin-bottom: 35px;
      color: #0E1932;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 150%;
      @media screen and (max-width: 1199px) {
        max-width: 390px;
        font-size: 1.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
    }
  }

  &__form-body {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 5;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .primary {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 175px;
      width: 100%;
      height: 54px;
      padding: 0;
      font-weight: 600;
      font-size: 1.6rem;
      @media screen and (max-width: 1199px) {

      }
      @media screen and (max-width: 767px) {
        max-width: 320px;
        margin: 0 auto;
      }
    }
  }



  .form-input {
    max-width: 210px;
    width: 100%;
    margin-right: 10px;
    @media screen and (max-width: 1199px) {
      max-width: 200px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.projects {
  position: relative;

  .title {

  }

  &__slider {
    position: relative;
    max-width: 1180px;
    width: 100%;
    padding: 0 20px;
    margin: 40px auto 0;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }

    .slider-shadow-left,
    .slider-shadow-right {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100px;
      z-index: 10;
      @media screen and (max-width: 767px) {
        width: 75px;
      }
    }
    .slider-shadow-left {
      left: -2px;
      background: linear-gradient(90deg, #03193D 0%, rgba(3, 25, 61, 0.00) 100%);
    }
    .slider-shadow-right {
      right: -2px;
      background: linear-gradient(270deg, #03193D 0%, rgba(3, 25, 61, 0.00) 100%);
    }

  }
  .swiper-slide {
    max-width: 520px;
    @media screen and (max-width: 767px) {
      max-width: 290px;
    }
  }

  .slide-img {
    width: 100%;
    height: 380px;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      height: 210px;
    }
  }

  .slide-desc {
    display: flex;
    flex-direction: column;
  }

  .desc-title {
    margin-top: 30px;
    margin-bottom: 15px;
    color: #F5F5F5;
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
    @media screen and (max-width: 1199px) {
      font-size: 2.8rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 2rem;
    }
  }

  .desc-item {
    margin-bottom: 7px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 155%;
    color: rgba(251, 251, 251, 0.40);
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
  }

  .accent {
    color: #FBFBFB;
  }

  .projects-button-prev,
  .projects-button-next {
    top: 50%;
    transform: translate(0, -50%);
    background-color: #FFFFFF;
    z-index: 20;
  }

  .swiper-button-disabled {
    display: none;
  }

  .projects-button-prev {
    display: none;
    left: 20px;
    &:before {
      border-left: 1px solid #0F2C73;
      border-bottom: 1px solid #0F2C73;
    }
  }

  .projects-button-next {
    right: 20px;
    &:before {
      border-top: 1px solid #0F2C73;
      border-right: 1px solid #0F2C73;
    }
  }
}

.form {
  padding-top: 110px;
  padding-bottom: 55px;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    border-radius: 10px;
    background: #FBFBFB;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {

    }
  }

  &__block-left {
    position: relative;
    max-width: 800px;
    width: 100%;
    padding: 50px 75px 50px 40px;
    @media screen and (max-width: 1199px) {
      padding: 45px 35px 80px 35px;
      max-width: 100%;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 15px 30px;
    }

    .title {
      max-width: 510px;
      color: #0E1932;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
      }
    }

    .subtitle {
      margin-top: 25px;
      margin-bottom: 45px;
      color: #0E1932;
      font-size: 2rem;
      font-weight: 400;
      line-height: 155%;
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        margin-bottom: 35px;
        font-size: 1.5rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  .input-wrap {
    position: relative;
    display: flex;
    padding-left: 160px;
    @media screen and (max-width: 767px) {
      padding-left: 105px;
    }
  }

  .input-label {
    position: absolute;
    left: 0;
    max-width: 135px;
    color: #0E1932;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      max-width: 100px;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .form-comment {
    max-width: 520px;
    width: 100%;
    height: 130px;
    padding: 14px 13px;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    resize: none;
    border-radius: 3px;
    border: 1px solid rgba(15, 44, 115, 0.20);
    background: #EAECF1;
  }

  .form-upload {
    display: flex;
    align-items: center;
    margin: 20px 0 35px 160px;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      margin: 15px 0 25px 120px;
    }
    @media screen and (max-width: 359px) {
      margin: 15px 0 25px 95px;
    }

    .upload-icon {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url("../img/upload-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      @media screen and (max-width: 767px) {
        width: 13px;
        height: 13px;
      }
    }

    .upload-name {
      position: relative;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 1.2rem;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px dashed rgba(14, 25, 50, .4);
      }
    }
  }

  .form-input {
    max-width: 287px;
    width: 100%;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__submit {
    max-width: 250px;
    margin-left: 160px;
    margin-bottom: 40px;
    font-size: 1.8rem;
    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 425px) {
      width: 100%;

    }
  }

  &__email {
    color: #0E1932;
    font-size: 2rem;
    font-weight: 400;
    line-height: 155%;
    .strong {
      font-weight: 700;
      @media screen and (max-width: 1199px) {
        display: block;
      }

    }
    @media screen and (max-width: 1199px) {
      position: absolute;
      right: 65px;
      bottom: 125px;
    }
    @media screen and (max-width: 959px) {
      display: block;
      position: static;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      margin-top: 25px;
    }
  }

  &__block-right {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 440px;
    width: 100%;
    padding: 60px 40px;
    background-color: #EAECF1;
    @media screen and (max-width: 1199px) {
      max-width: 100%;
      padding: 40px 35px;
    }
    @media screen and (max-width: 767px) {
      padding: 30px 15px 50px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      background-image: url("../img/form-foto-2.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 384px;
      height: 404px;
      z-index: 5;
      @media screen and (max-width: 1199px) {
        left: auto;
        right: 0;
        transform: none;
      }
      @media screen and (max-width: 959px) {
        right: -90px;
      }
      @media screen and (max-width: 767px) {
        width: 191px;
        height: 249px;
        right: -15px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url("../img/decor-form-1.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 555px;
      height: 360px;
      z-index: 2;
      @media screen and (max-width: 1199px) {
        left: auto;
        right: 0;
      }
      @media screen and (max-width: 767px) {
        width: 280px;
      }
    }

    .title {
      position: relative;
      max-width: 320px;
      color: #0E1932;
      z-index: 10;
      @media screen and (max-width: 1199px) {
        max-width: 490px;
      }
      @media screen and (max-width: 767px) {
        max-width: 225px;
      }
    }

    .subtitle {
      position: relative;
      max-width: 290px;
      margin-top: 15px;
      margin-bottom: 30px;
      color: #0E1932;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 150%;
      z-index: 10;
      @media screen and (max-width: 1199px) {
        max-width: 100%;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 767px) {
        max-width: 225px;
        font-size: 1.5rem;
      }
    }

    .primary {
      position: relative;
      max-width: 250px;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 140%;
      z-index: 10;
      @media screen and (max-width: 767px) {
        max-width: 180px;
        font-size: 1.5rem;
      }
    }
  }

  &__block-phone {
    position: relative;
    margin-top: 35px;
    margin-bottom: 5px;
    color: #0E1932;
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.6px;
    z-index: 10;
    @media screen and (max-width: 1199px) {
      margin-top: 40px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }

  &__block-text {
    max-width: 260px;
    color: #0E1932;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      max-width: 140px;
      font-size: 1.5rem;
    }
  }
}

.map {
  padding-top: 55px;
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: row;
      justify-content: normal;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text {
    max-width: 365px;
    @media screen and (max-width: 1199px) {
      max-width: 320px;
      margin-bottom: 50px;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
      max-width: 100%;
      margin-bottom: 40px;
    }
    .subtitle {
      margin-top: 25px;
      color: rgba(255, 255, 255, 0.80);
      font-size: 2rem;
      font-weight: 400;
      line-height: 155%;
      @media screen and (max-width: 1199px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 767px) {
        margin-top: 15px;
        font-size: 1.5rem;
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      max-width: 320px;
      margin-left: 145px;
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
      text-align: center;
      max-width: 100%;
      margin-left: 0;
      margin-bottom: 40px;
    }

    .contact-phone {
      margin-bottom: 5px;
      color: #FBFBFB;
      font-size: 3rem;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.6px;
      @media screen and (max-width: 1199px) {
        font-size: 2.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
    }

    .phone-subtitle {
      color: rgba(255, 255, 255, 0.80);
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
    }

    .contact-email {
      margin: 25px 0;
      color: #FBFBFB;
      font-size: 3rem;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.6px;
      @media screen and (max-width: 1199px) {
        font-size: 2.8rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
    }

    .primary {
      max-width: 135px;
      padding: 16px 0;
      @media screen and (max-width: 1199px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }
  }

  &__img {
    width: 750px;
    height: 526px;
    border-radius: 10px;
    object-fit: cover;
    @media screen and (max-width: 1199px) {
      width: 100%;
      height: 475px;
    }
    @media screen and (max-width: 425px) {
      height: 230px;
    }
  }
}
