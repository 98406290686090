@import "color.scss";
@import "header.scss";
@import "main.scss";
@import "footer.scss";
@import "title.scss";
@import "buttons.scss";
@import "container.scss";

* {
  outline: none;
  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}


body {
  font-family: 'Inter', sans-serif;
  background-color: #03193D;
  overflow-x: hidden;
}

:root {
  font-size: 10px;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  color: $white-text;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: .3s;
}

input,
button,
textarea {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .3s;
  outline: 0;
}

.logo {
  display: flex;
  align-items: center;
  z-index: 25;
}
.fancybox-active.compensate-for-scrollbar {
  margin: 0;
}

.logo-img {
  width: 138px;
  height: 47px;
  object-fit: contain;
  @media screen and (max-width: 1199px) {
    width: 100px;
    height: 34px;
  }
  @media screen and (max-width: 767px) {
    width: 80px;
    height: 26px;
  }
}

.logo-text {
  margin-left: 14px;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 143%;
  @media screen and (max-width: 1199px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 860px) {
    display: none;
  }
}


td {
  padding: 0;
  border: none;
  border-spacing: 0;
}

.strong {
  font-weight: 700;
}

.form-input {
  height: 54px;
  padding: 14px 13px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;
  border-radius: 3px;
  border: 1px solid rgba(15, 44, 115, 0.20);
  background: #EAECF1;
}

.form-decor {
  position: absolute;
  right: 30px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  max-width: 260px;
  @media screen and (max-width: 1199px) {
    bottom: 50px;
  }
  @media screen and (max-width: 959px) {
    max-width: 180px;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    flex-direction: row;
    max-width: 100%;
    margin: 25px auto 0;
    right: auto;
    bottom: auto;
  }
}

.img-wrapper {
  position: relative;
  width: 78px;
  height: 78px;
  margin-bottom: 11px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    border: 3px solid $white-text;
    background-color: $marker-color;
    z-index: 5;
  }
}

.decor-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.decor-text {
  color: $main-body;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 140%;
  @media screen and (max-width: 767px) {
    max-width: 230px;
    margin-left: 20px;
  }
  @media screen and (max-width: 375px) {
    font-size: 1.2rem;
    max-width: 180px;
  }
  @media screen and (max-width: 359px) {
    max-width: 150px;
  }
}
