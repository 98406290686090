@import "color.scss";

button.primary {
  display: flex;
  justify-content: center;
  padding: 22px 25px;
  font-weight: 600;
  line-height: 140%;
  color: $dark-text;
  border-radius: 3px;
  background: $btn-primary;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background: transparent;
    color: $btn-primary;
    box-shadow: 0 0 0 2px rgba(255, 182, 0, 1) inset;
  }
  @media screen and (max-width: 1199px) {
    padding: 18px 20px;
    font-size: 1.8rem;
  }
  @media screen and (max-width: 767px) {
    max-width: 320px;
    width: 100%;
    padding: 15px 0;
    font-size: 1.5rem;
  }
}

button.secondary {
  text-align: center;
  padding: 10px 11px;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 140%;
  color: $dark-text;
  border-radius: 3px;
  background: $btn-secondary;
  cursor: pointer;
  @media screen and (max-width: 1199px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
  }
}

.scroll-btn {
  display: block;
  width: 48px;
  height: 48px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background-color: $btn-secondary;
    .scroll-btn-arrow {
      fill: $dark-text;
      transition: .3s;
    }
  }
}

.button-prev,
.button-next {
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #1C3050;
  border: 1px solid #696a83;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
    display: block;
    width: 40%;
    height: 40%;
  }
}

.button-prev {
  &:before {
    left: 40%;
    border-left: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
  }
}

.button-next {
  &:before {
    right: 40%;
    border-top: 1px solid #FFF;
    border-right: 1px solid #FFF;
  }
}

.pagination {

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 0.2;
    background: #37B2EF;
    cursor: pointer;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
  }
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 2px;
    border-radius: 4px;
    background-color: $main-body;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

