@import "color.scss";

h1.title {
  font-size: 7.2rem;
  font-weight: 800;
  line-height: 107%;
  letter-spacing: -1.455px;
  @media screen and (max-width: 1199px) {
    font-size: 5rem;
    letter-spacing: -1px;
  }
  @media screen and (max-width: 767px) {
    font-size: 3.2rem;
    letter-spacing: -0.64px;
  }
}

h2.title {
  font-size: 5.5rem;
  font-weight: 900;
  line-height: 107%;
  letter-spacing: -1.1px;
  @media screen and (max-width: 1199px) {
    font-size: 4.5rem;
    letter-spacing: -0.9px;
  }
  @media screen and (max-width: 767px) {
    font-size: 2.8rem;
    letter-spacing: -0.56px;
  }
}

h3.title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.7px;
  @media screen and (max-width: 1199px) {
    font-size: 3rem;
    letter-spacing: -0.6px;
  }
  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
    letter-spacing: -0.48px;
  }
}