@import "color.scss";

.footer {
  position: relative;
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding-bottom: 70px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    background-image: url("../img/footer-decor.svg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1400px;
    height: 350px;
    z-index: -5;
    @media screen and (max-width: 767px) {
      height: 725px;
    }
  }

  &__wrapper {
    position: relative;
    padding-top: 35px;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0.2;
      background: #FFF;
    }

    .logo {
      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }

      .logo-text {
        @media screen and (max-width: 767px) {
          display: block;
          margin-left: 0;
          margin-top: 7px;
        }
      }
    }
  }

  &__title {
    margin-top: 25px;
    margin-bottom: 15px;
    color: #FFF;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
      font-size: 1.5rem;
    }
  }

  &__contact {
    color: #FBFBFB;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, .2);
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  .diple {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #FBFBFB;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 140%;
    text-decoration: underline;
    text-decoration-color: rgba(255, 255, 255, .2);
    @media screen and (max-width: 767px) {
      position: static;
      margin-top: 50px;
      font-size: 1.5rem;
    }
  }

  .top-scroll {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: fixed;
    bottom: 30px;
    right: -100%;
    z-index: 50;
    border-radius: 3px;
    //background-color: rgba(255, 255, 255, .2);
    background-color: $dark-text;
    border: 2px solid $white-text;
    transition: .3s;
    &:after {
      content: '';
      position: absolute;
      background-image: url("../img/arrow-top.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.active {
      right: 20px;
    }
    @media screen and (max-width: 767px) {
      width: 48px;
      height: 48px;
      bottom: 75px;
    }
  }
}

.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(60px);

  display: none;
  z-index: 100;
  &.active {
    display: block;
  }
}

.modal {
    width: calc(100% - 40px);
    max-width: 800px;
    max-height: 95vh;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 20px 25px 30px;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 10px;
    background-color: $white-text;
    transition: .3s;
    z-index: 110;
    &.active {
      transform: translate(-50%, -50%) scale(1);
    }
  @media screen and (max-width: 1199px) {
    padding: 25px 15px;
  }

  .form-body {
    width: 100%;
    z-index: 10;
  }


  .input-wrap {
    position: relative;
    display: flex;
  }

  .input-label {
    position: absolute;
    max-width: 135px;
    color: #000;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      max-width: 84px;
      font-size: 1.5rem;
      word-wrap: break-word;
    }
  }

  .form-comment {
    width: 100%;
    max-width: 525px;
    height: 130px;
    padding: 14px 13px;
    margin-bottom: 20px;
    resize: none;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    font-size: 1.8rem;
    background: #EAECF1;
  }

  .input-upload {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;

    .upload-icon {
      display: block;
      background-image: url("../img/upload-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .upload-desc {
      position: relative;
      color: $main-body;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      &:before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        border-bottom: 1px dashed rgba(14, 25, 50, .4);
      }
    }
  }

  .form-input {
    width: 290px;
    height: 54px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    font-size: 1.8rem;
    background: #EAECF1;
    @media screen and (max-width: 767px) {
      max-width: 215px;
      width: 100%;
    }
  }

  .form-comment,
  .form-input,
  .input-upload {
    margin-left: 160px;
    @media screen and (max-width: 767px) {
      margin-left: 105px;
    }
  }
  .primary {
    margin-top: 15px;
    margin-left: 160px;
    font-size: 1.8rem;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__calculation,
  &__discount {
    display: flex;
    flex-direction: column;
    align-items: start;

    @media screen and (max-width: 767px) {
      align-items: center;
      max-width: 350px;
      padding-top: 50px;
    }

    .title {
      color: $main-body;
      font-size: 3.2rem;
      @media screen and (max-width: 767px) {
        font-size: 2.8rem;
      }
    }

    .subtitle {
      margin-top: 10px;
      margin-bottom: 30px;
      color: $main-body;
      font-size: 1.8rem;
    }

    .modal-link {
      position: absolute;
      right: 85px;
      bottom: 25px;
      color: $main-body;
      font-size: 2rem;
      line-height: 155%;
      z-index: 15;
      .strong {
        display: block;
        font-size: 2rem;
        line-height: 155%;
        @media screen and (max-width: 767px) {
          font-size: 1.5rem;
        }
      }

      @media screen and (max-width: 1199px) {
        position: static;
        text-align: center;
        margin: 25px auto 0;
        font-size: 1.5rem;
      }
    }
  }

  &__discount {

    .title {
      margin-bottom: 50px;
    }
  }

  &__question,
  &__callback {
    max-width: 600px;
    padding: 15px 30px 48px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      background-image: url("../img/modal-decor.png");
      background-size: cover;
      background-repeat: no-repeat;
      width: 490px;
      height: 315px;
      @media screen and (max-width: 767px) {
        right: -300px;
      }
    }

    .title {
      max-width: 320px;
      font-size: 2.8rem;
      color: $dark-text;
    }

    .subtitle {
      margin-top: 8px;
      margin-bottom: 26px;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 140%;
      color: $dark-text;
    }

    .form-body {
      display: flex;
      flex-direction: column;

    }

    .form-input {
      max-width: 200px;
      margin-left: 0;
    }

    .submit {
      max-width: 180px;
      margin-left: 0;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 120%;
    }
  }

}

.toast {
  position: fixed;
  right: -100%;
  top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 210px;
  height: 50px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background-color: $btn-primary;
  transition: .3s;
  &__text {
    font-size: 1.5rem;
    font-weight: 600;
    color: $dark-text;
  }
  &.active {
    right: 0;
  }
}

.quick-menu {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 15px 0;
  background: rgba(48, 49, 56, 0.50);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  z-index: 50;
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__link {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    @media screen and (max-width: 425px) {
      width: 30px;
      height: 30px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @media screen and (max-width: 425px) {
        width: 80%;
        height: 80%;
      }
    }
  }

  .phone {
    &:before {
      background-image: url("../img/phone.svg");
    }
  }

  .whats {
    &:before {
      background-image: url("../img/whatsapp.svg");
    }
  }

  .email {
    &:before {
      background-image: url("../img/email.svg");
    }
  }

  .telega {
    &:before {
      background-image: url("../img/telegram.svg");
    }
  }
}
